.story {

    &__blockquote {
        font-size: 3.25rem;
        position: relative;
        z-index: 10;
        grid-column: quote-start / quote-end;
        grid-row: quote-start / quote-end;
        padding: 0;
        margin: 0;
        display: block;
        align-self: center;

        @include breakpoint(sm) {

            font-size: 2.3rem;

        }

        @media screen and (orientation: landscape) {

            @include breakpoint(md) {

                font-size: 2.3rem;
                
            }

        }

        &::before {

            top: 0;
            left: 0;
            display: block;
            line-height: 1;
            margin: 0;
            content: '';
            position: relative;
            //top: 1.5rem;
            //left: 3rem;
            z-index: 100;
            width: 8rem;
            height: 8rem;
            background-size: contain;
            background-repeat: no-repeat;
            transform: translateX(-50%);
            background-blend-mode: multiply;
            z-index: 15000;

            @include breakpoint(sm) {
    
                opacity: 0.8;
                display: block;
                left: 50%;
                transform: translate(-50%, -60%);

            }

        }

    }

    &__quote {

        align-self: center;
        line-height: 3.5rem;
        margin: 0;
        
        span {

            font-weight: 600;

        }
    } 

    &__name {

        text-align: right;

        &::before, &::after {

            content: '-';
            position: relative;
            display: inline;

        }



    }

}

.intro, .about {

    z-index: -10;
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;

    &::before {

        z-index: 10;
        position: absolute;
        top: -100%;
        left: -100%;
        height: 300%;
        width: 300%;
        content: '';
        background-image: url(../img/doodle_pattern.svg);
        background-size: 20%;
        background-position: center;
        filter: invert(1);
        opacity: .3;
        transform: rotate(-15deg)

    } 

    &__background {

        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        height: 300vh;

    }

}

.intro {
    
    &__definition {

        align-self: center;
        min-width: 30rem;
        width: fit-content;
        background: rgba($white, .9);
        padding: 3rem 5rem;
    
    }


    &__background {
        transform: translateY(-25%);

    }

}

.about {

&__textblock {

    align-self: center;
    min-width: 30rem;
    width: 33%;
    background: rgba($white, .9);
    padding: 3rem 5rem;

}

&__text {

font-size: 2.2rem;

}

    &__background {

        transform: translate(-60%, -25%);

    }

}
.story {

    max-width: 100vw;
    position: relative;
    display: grid;
    background-color: $white;
    height: 100%;
    overflow: hidden;

    &__design {

        position: absolute;
        mix-blend-mode: multiply;
        z-index: 10000;
        width: 100%;

        @include breakpoint(sm) {

         display: none;

        }

    }

    &__doodle {

        grid-column: doodle-start / doodle-end;
        grid-row: doodle-start / doodle-end;
        height: 30rem;

        @include breakpoint(sm) {

            opacity: 0.33;
        
        }

        @media screen and (orientation: landscape) {

            opacity: 0.33;
        
        }

    }

    &--top-right &__doodle, &--top-left &__doodle {

        align-self: end;

    }

    &--bottom-right &__doodle, &--bottom-left &__doodle {

        align-self: start;

    }

    &--top-right {

        @include grid-story(right, top);

    }

    &--top-left {

        @include grid-story(left, top);

    }

    &--bottom-right {

        @include grid-story(right, bottom);

    }

    &--bottom-left {

        @include grid-story(left, bottom);

    }

    &--york {

        .story__design {

            height: 70vw; 
            transform: rotate(-90deg);
            transform-origin: top;
            top: 120vh;
            left: -50%;

        }

    }

    &--marja {

        .story__design {

            height: 70vw; 
            transform: rotate(-90deg);
            transform-origin: top;
            top: -25vh;
            left: -40%;

        }

    }    
 
    &--annamari {

        .story__design {

            height: 40vw;
            top: 70vh;
            left: 50%;
            transform: translateX(-50%);

        }

    }   

    &--alice, &--virva {

        .story__design {

            height: 70vw; 
            transform: rotate(90deg);
            transform-origin: top;
            top: -20vh;
            left: 50%;

        }

    }

    &--henry, &--tobias {

        .story__design {

            height: 70vw; 
            transform: rotate(25deg) scaleX(-1) ;
            top: 20vh;
            right: -52vw;

        }

    }

    &--outi-ville {

        .story__design {

            height: 50vw; 
            transform: rotate(-25deg) translateY(-33%);
            top: 0;
            right: -52vw;

        }

    }

    &--henri, &--chris {

        .story__design {

            height: 50vw; 
            transform: rotate(-25deg) translateY(-33%);
            top: 0;
            right: -52vw;

        }

    }       

    &--peter {

        .story__design {

            height: 40vw; 
            transform: rotate(90deg);
            top: -65%;
            left: -10%;

        }

    }  
    
    &--annikki-eveliina {

        .story__design {

            height: 50vw; 
            transform: translateY(-10%);
            top: 0;
            right: -52vw;

        }

    }  

    &--redouane {

        .story__design {

            height: 35vw; 
            transform: rotate(180deg);
            top: -40%;
            left: -15%;

        }

    } 

    &--pia {

        .story__design {

            height: 70vw; 
            transform: rotate(-115deg);
            bottom: -60vh;
            right: 0;

        }

    }  

    &__slider {

        grid-column: picture-start / picture-end;
        grid-row: picture-start / picture-end;
        width: 100%;
        height: 100%;
        overflow: hidden;
   }

   &__picture {

        background-color: red;

        img {

            height: 100%;
            width: 100%;
            object-fit: cover;

        }

   }

    &__portrait, &__action {

        background-color: red;
        height: 500px;

    }

    &__details {

        position: absolute;
        top: 0;

    }

}
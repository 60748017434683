@mixin grid-story($horizontal, $vertical) {

    @if $horizontal == right {

        grid-template-columns: [section-start picture-start] repeat(6, 1fr) [picture-end] 1fr [quote-start doodle-start] repeat(4, 1fr) [quote-end] 1fr [section-end];

        @include breakpoint(lg) {

            @media screen and (orientation: portrait) {
            grid-template-columns: [section-start picture-start] 1fr [doodle-start] repeat(5, 1fr) [doodle-end quote-start ] repeat(5, 1fr) [quote-end] 1fr [picture-end section-end];    
            }

         } 

         @include breakpoint(sm) {

            @media screen and (orientation: portrait) {
            grid-template-columns: [section-start picture-start] 1fr [doodle-start quote-start ] repeat(10, 1fr) [doodle-end quote-end] 1fr [picture-end section-end];    
            }

         } 

    }

    @else if $horizontal == left {

        grid-template-columns: [section-start] 1fr [quote-start doodle-start] repeat(4, 1fr) [quote-end doodle-end] 1fr [picture-start] repeat(6, 1fr) [picture-end section-end];
    
        @include breakpoint(lg) {

            @media screen and (orientation: portrait) {
            grid-template-columns: [section-start picture-start] 1fr [quote-start] repeat(5, 1fr) [quote-end doodle-start ] repeat(5, 1fr) [doodle-end] 1fr [picture-end section-end];
            }
    
         } 

         @include breakpoint(sm) {

            @media screen and (orientation: portrait) {
            grid-template-columns: [section-start picture-start] 1fr [doodle-start quote-start ] repeat(10, 1fr) [doodle-end quote-end] 1fr [picture-end section-end];    
            }

         } 


    }

   @if  $vertical == top {

      grid-template-rows: [section-start picture-start ] 1fr [quote-start doodle-start] 3fr [quote-end ] 3fr [doodle-end] 1fr [picture-end section-end];
      
     @include breakpoint(lg) {

        @media screen and (orientation: portrait) {
        grid-template-rows: [section-start picture-start ] 2fr [picture-end doodle-start quote-start ] 1fr [quote-end doodle-end section-end];
        }

     } 

   }

   @else if  $vertical == bottom {

    grid-template-rows: [section-start picture-start ] 1fr [doodle-start] 3fr [quote-start] 3fr [doodle-end quote-end] 1fr [picture-end section-end];

    @include breakpoint(lg) {

        @media screen and (orientation: portrait) {
        grid-template-rows: [section-start picture-start ] 2fr [picture-end doodle-start quote-start ] 1fr [quote-end doodle-end section-end];
        }

     } 

}


}

.body {

    font-size: 2rem;
    font-family: atten-round-new, sans-serif;
    font-weight: 400;


}

.headline, .marked {

    font-family: atten-round-new, sans-serif;
    font-weight: 800;

}
// COLOR

$grey-light: #F2F2F2;
$white: #FFFFFF;
$blue: #57b0ff;
$yellow: #ffda05;
$orange: #ff9233;
$green: #9ce831;


// GRID 

$container-width: 77.5em;
$columns: 12;
$grid-gutter-width: 1.875em !default;

$column-width: $container-width / $columns;

$container-grid-columns: [container-start] repeat($columns, [col-start] minmax(min-content, $column-width) [col-end] ) [container-end];
$full-grid-columns: [full-start] minmax($grid-gutter-width, 1fr) $container-grid-columns minmax($grid-gutter-width, 1fr) [full-end];

$grid-column-container-width: container-start / container-end;
$grid-column-full-width: full-start / full-end;


// BREAKPOINTS 

$breakpoint-xxl: $container-width + $grid-gutter-width * 2;
$breakpoint-xl: 68.75em;
$breakpoint-lg: 56.25em;
$breakpoint-md: 43.75em;
$breakpoint-sm: 31.25em;
$breakpoint-xs: 18.75em;

// TYPOGRAPHY

$font-size-base: 2rem;
$font-size-smaller: $font-size-base * .8;
$font-size-larger: $font-size-base * 1.2;
$font-size-md: 4rem;
$font-size-lg: 5rem;
$font-size-xl: 7.5rem;
$font-size-xxl: 9rem;

// SPACING

$spacer: 	2rem !default;
$spacer-sm: $spacer * .5 !default;
$spacer-lg: $spacer * 2.5 !default;
$spacer-xl: $spacer-lg * 2 !default;

$section-padding-y:  $spacer-xl 0;
$section-padding-y-lg: $spacer-lg*1.5 0;
$section-padding-y-md:	$spacer-lg 0;
$section-padding-y-sm:  $spacer 0;
$section-padding-top: $spacer-xl * 5;

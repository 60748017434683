// BREAKPOINTS 

@mixin breakpoint($point) {
	 
    @if $point == xxl {
       
         @media (max-width: $breakpoint-xxl) { @content ; }
    }
       
    @else if $point == xl {
       
         @media (max-width: $breakpoint-xl) { @content ; }
    }
       
    @else if $point == lg {
       
          @media (max-width: $breakpoint-lg) { @content ; }
    }
       
    @else if $point == md {
       
         @media (max-width: $breakpoint-md)  { @content ; }
    }
       
    @else if $point == sm {
       
        @media (max-width: $breakpoint-sm)  { @content ; }
  
    }
       
    @else if $point == xs {
       
        @media (max-width: $breakpoint-xs)  { @content ; }
  
    }
  }
  
 
  /***************************************************
  
          R E S P O N S I V E  S P A C I N G
  
  ****************************************************/
  
  // MARGIN 
  
  @mixin margin-bottom-xl {
      
      margin-bottom: $spacer-xl;	
      
      @include breakpoint(lg) {
          
          margin-bottom: $spacer-lg * 1.5	
          
      }
      
      @include breakpoint(md) {
          
          margin-bottom: $spacer-lg;
          
      }
      
      @include breakpoint(sm) {
          
          margin-bottom: $spacer;
          
      }
      
  }
  @mixin margin-bottom-sm {
      
      margin-bottom: $spacer;	
      
      @include breakpoint(lg) {
          
          margin-bottom: $spacer * .5;	
          
      }
          
  }
  
  // SECTION PADDING
  
  @mixin section-padding-y {
      
      padding: $section-padding-y;	
      
      @include breakpoint(lg) {
          
          padding: $section-padding-y-lg;	
          
      }
      
      @include breakpoint(md) {
          
          padding: $section-padding-y-md;	
          
      }
      
      @include breakpoint(sm) {
          
          padding: $section-padding-y-sm;	
          
      }
      
  }
  
  @mixin section-padding-top {
      
      padding: $section-padding-y 0 0;	
      
      @include breakpoint(lg) {
          
          padding: $section-padding-y-lg 0 0;	
          
      }
      
      @include breakpoint(md) {
          
          padding: $section-padding-y-md 0 0;	
          
      }
      
      @include breakpoint(sm) {
          
          padding: $section-padding-y-sm 0 0;	
          
      }
      
  }
  
  // CONTAINER PADDING XL
  
  @mixin padding-spacer-xl {
      
      padding: $spacer-xl;
      
      @include breakpoint(xl) {
          
          padding: $spacer-lg * 1.5;
          
      }
      
      @include breakpoint(lg) {
          
          padding: $spacer-lg;
          
      }
      
      @include breakpoint(sm) {
          
          padding: $spacer * 2;
          
      }
      
      @include breakpoint(xs) {
          
          padding: $spacer * 1.5;
          
      }
      
      
  }
  
  
  @mixin padding-spacer-y {
      
      padding: $spacer-xl 0;
      
      @include breakpoint(xl) {
          
          padding: $spacer-lg * 1.5 0;
          
      }
      
      @include breakpoint(lg) {
          
          padding: $spacer-lg 0 ;
          
      }
      
      @include breakpoint(md) {
          
          padding: $spacer 0;
          
      }
      
  }
  
  // GRID COLUMN GAP
  
  @mixin grid-column-gap-xl {
      
      grid-column-gap: $spacer-xl;
      
      @include breakpoint(xl) {
          
          grid-column-gap: $spacer-lg * 1.5;
          
      }
      
      @include breakpoint(lg) {
          
          grid-column-gap: $spacer-lg;
          
      }
      
      @include breakpoint(md) {
          
          grid-column-gap: $spacer;
          
      }
      
  }
  
  @mixin grid-column-gap-sm {
      
      grid-column-gap: $grid-gutter-width;
          
      @include breakpoint(lg) {
          
          grid-column-gap: $grid-gutter-width * .67;
          
      }
      
      @include breakpoint(md) {
          
          grid-column-gap: $grid-gutter-width * .33;
          
      }
      
  }
  
  // GRID ROW GAP
  
  @mixin grid-row-gap-xl {
      
      grid-row-gap: $spacer-xl;
      
      @include breakpoint(xl) {
          
          grid-row-gap: $spacer-lg * 1.5;
          
      }
      
      @include breakpoint(lg) {
          
          grid-row-gap: $spacer-lg;
          
      }
      
      @include breakpoint(md) {
          
          grid-row-gap: $spacer;
          
      }
      
  }
  
  @mixin grid-row-gap-sm {
      
      grid-row-gap: $grid-gutter-width;
          
      @include breakpoint(lg) {
          
          grid-row-gap: $grid-gutter-width * .67;
          
      }
  
      
  }
  
  

  
  // 
  
  @mixin section-container-columns($columns, $column-gap, $row-gap) {
      
      display: grid;
      grid-template-columns: [full-start] minmax($grid-gutter-width, 1fr) [container-start] repeat($columns, [col-start] minmax(1rem, $container-width/$columns) [col-end] ) [container-end] minmax($grid-gutter-width, 1fr) [full-end];
      
      @if $column-gap == none { 
      
          grid-column-gap: none;
          
      }
          
      @else if $column-gap == xl {
          
          @include grid-column-gap-xl	
              
          }		
      
      @else if $column-gap == sm {
          
          @include grid-column-gap-sm	
              
          }
      
      @if $row-gap == none { 
      
          grid-row-gap: none;
          
      }
          
      @else if $row-gap == xl {
          
          @include grid-row-gap-xl	
              
          }		
      
      @else if $row-gap == sm {
          
          @include grid-row-gap-sm	
              
          }
          
  }

  @mixin section-container-columns($picture, $column-gap, $row-gap) {
      
    display: grid;
    grid-template-columns: [full-start] minmax($grid-gutter-width, 1fr) [container-start] repeat($columns, [col-start] minmax(1rem, $container-width/$columns) [col-end] ) [container-end] minmax($grid-gutter-width, 1fr) [full-end];
    
    @if $picture == right {

        grid-template-columns: [full-start] minmax($grid-gutter-width, 1fr) [text-start] minmax(min-content, $container-width*0.3333) 

    }  

    @if $column-gap == none { 
    
        grid-column-gap: none;
        
    }
        
    @else if $column-gap == xl {
        
        @include grid-column-gap-xl	
            
        }		
    
    @else if $column-gap == sm {
        
        @include grid-column-gap-sm	
            
        }
    
    @if $row-gap == none { 
    
        grid-row-gap: none;
        
    }
        
    @else if $row-gap == xl {
        
        @include grid-row-gap-xl	
            
        }		
    
    @else if $row-gap == sm {
        
        @include grid-row-gap-sm	
            
        }
        
}

  
  @mixin container-columns($columns, $column-gap, $row-gap) {
      
      display: grid;
      grid-template-columns: [container-start] repeat($columns, [col-start] minmax(1rem, $container-width/$columns) [col-end] ) [container-end];
      
      @if $column-gap == none { 
      
          grid-column-gap: none;
          
      }
          
      @else if $column-gap == xl {
          
          @include grid-column-gap-xl	
              
          }		
      
      @else if $column-gap == sm {
          
          @include grid-column-gap-sm	
              
          }
      
      @if $row-gap == none { 
      
          grid-row-gap: none;
          
      }
          
      @else if $row-gap == xl {
          
          @include grid-row-gap-xl	
              
          }		
      
      @else if $row-gap == sm {
          
          @include grid-row-gap-sm	
              
          }
          
  }
  
  @mixin container-columns-responsive($columns, $column-gap, $row-gap) {
      
      grid-template-columns: [container-start] repeat(auto-fill, [col-start] minmax($container-width/$columns, 1fr) [col-end] ) [container-end];
      
      @if $column-gap == none { 
      
          grid-column-gap: none;
          
      }
          
      @else if $column-gap == xl {
          
          @include grid-column-gap-xl;	
              
          }		
      
      @else if $column-gap == sm {
          
          @include grid-column-gap-sm;	
              
          }
      
      @if $row-gap == none { 
      
          grid-row-gap: none;
          
      }
          
      @else if $row-gap == xl {
          
          @include grid-row-gap-xl	
              
          }		
      
      @else if $row-gap == sm {
          
          @include grid-row-gap-sm	
              
      }
          
  }
  
  @mixin section-container-columns($columns, $column-gap, $row-gap) {
      
    display: grid;
    grid-template-columns: [full-start] minmax($grid-gutter-width, 1fr) [container-start] repeat($columns, [col-start] minmax(1rem, $container-width/$columns) [col-end] ) [container-end] minmax($grid-gutter-width, 1fr) [full-end];
    
    @if $column-gap == none { 
    
        grid-column-gap: none;
        
    }
        
    @else if $column-gap == xl {
        
        @include grid-column-gap-xl	
            
        }		
    
    @else if $column-gap == sm {
        
        @include grid-column-gap-sm	
            
        }
    
    @if $row-gap == none { 
    
        grid-row-gap: none;
        
    }
        
    @else if $row-gap == xl {
        
        @include grid-row-gap-xl	
            
        }		
    
    @else if $row-gap == sm {
        
        @include grid-row-gap-sm	
            
        }
        
}

  /************************************
  
          T Y P O G R A P H Y
  
  ************************************/
  
  // TYOPGRAPHY
  
  @mixin headline-primary {
          
      font-size: $font-size-xl;
      line-height: 1.2;
          
  }
  
  @mixin headline-secondary {
          
      font-size: $font-size-lg;
      font-weight: 600;
      line-height: 1.2;
      
      @include breakpoint(md) {
          
          font-size: $font-size-md;
          
      }
      
      @include breakpoint(xs) {
          
          font-size: $font-size-md * .7;
          
      }
          
  }

.green {

    & .story {
        
        &__blockquote {

            &::before {
            
                background-image: url(../img/SVG/hymy_qumark_green.svg);
        
            }   
            
        }       

        &__quote span {

            color: $green;

        }

        &__design {

            fill: $green;

        }        

    } 

}

.yellow {
    
    & .story {
        
        &__blockquote {

            &::before {
            
                background-image: url(../img/SVG/hymy_qumark_yellow.svg);
        
            }   
            
        }       

        &__quote span {

            color: $yellow;

        }

        &__design {

            fill: $yellow;

        }

    }  

}


.blue {
    
    & .story {
        
        &__blockquote {

            &::before {
            
                background-image: url(../img/SVG/hymy_qumark_blue.svg);
        
            }   
            
        }       

        &__quote span {

            color: $blue;

        }

        &__design {

            fill: $blue;

        }        

    }  

}


.orange {
    
    & .story {
        
        &__blockquote {

            &::before {
            
                background-image: url(../img/SVG/hymy_qumark_orange.svg);
        
            }   

        }       

        &__quote span {

            color: $orange;

        }

        &__design {

            fill: $orange;

        }        

    }    

}

.hero {

    width: 100vw;
    display: flex;
    position: relative;
    height: 100%;
    overflow: hidden;
    justify-content: center;

    &::after {

        z-index: -1;
        position: absolute;
        top: -100%;
        left: -100%;
        height: 300%;
        width: 300%;
        content: '';
        background-image: url(../img/doodle_pattern.svg);
        background-size: 20%;
        background-position: center;
        opacity: 0.3;
        transform: rotate(-15deg)

    }


    &__wordmark {

       width: 55vw;
       min-width: 90vh;

       justify-self: center;

         @include breakpoint(lg) {

            @media screen and (orientation: portrait) {

                max-width: 85vh;
                position: absolute;
                right: 0%;
                top: 50%;
                transform: rotate(-90deg) translate(30%, 50%) scale(1.1);

            }

        } 
    }

}
